import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  avatarContainer: {
    display: "flex",
  },
  avatar: {
    marginRight: "15px",
  },
  messageContainer: {
    padding: "0 4%",
    display: "flex",
  },
  messageContent: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    position: "relative",
  },
  sent: {
    justifyContent: "flex-end",
  },
  deleteSentMessage: {
    position: "absolute",
    right: "2px",
  },
  deleteButton: {
    color: theme.palette.icon.secondary,
    marginTop: "4px",
    padding: "0",
    opacity: 0.7,

    "&:hover": {
      opacity: 1,
    },
  },
  deleteIcon: {
    height: "14px",
    width: "14px",
  },
}));
