import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  chatList: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  chatListContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  searchContainer: {
    padding: theme.spacing(1),
    "& > div:before, div:after": { display: "none" },
  },
}));

export default useStyles;
