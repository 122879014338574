import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: "calc(100% - 16px)",
  },
  promptText: {
    width: "60%",
  },
  pollIcon: {
    color: theme.palette.icon.primary,
  },
}));
