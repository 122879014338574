import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { getUserName } from "modules/userProfile";
import { useStyles } from "./styles";
import MessageTime from "./MessageTime";
import { IMessageTypeProps } from "../Message";

const EmojiMessage = (props: IMessageTypeProps) => {
  const { message, author, isMe = false } = props;
  const classes = useStyles();

  if (isMe) {
    return (
      <div className={classes.emoji}>
        {message.text}
        <MessageTime time={message.time} />
      </div>
    );
  }

  return (
    <div className={classNames(classes.text, classes.received)}>
      <Typography variant="body2" className={classes.authorName}>
        {getUserName(author)}
      </Typography>
      <div className={classes.emoji}>
        <span>{message.text}</span>
        <MessageTime time={message.time} />
      </div>
    </div>
  );
};

export default EmojiMessage;
