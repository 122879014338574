import { useCallback } from "react";
import { useSelector } from "react-redux";
import { resetChat, updateActiveChannel } from "modules/chat/redux/actions";
import { useTranscriptionRequests } from "modules/transcription";
import { useUserSession } from "modules/eventUserPresence/hooks/userSession";
import { selectCurrentEventDirectories } from "modules/eventDirectory/redux/selectors";
import logger from "logging/logger";
import { resetPresenceInfo } from "modules/eventUserPresence/redux/reducer";
import { resetAudienceViewState } from "modules/broadcast/AudienceView/actions";
import { resetLobby } from "modules/lobby/redux/slice";
import { reset as resetRooms } from "services/firebaseService/room";
import { resetSpace } from "modules/space/redux/spaceSlice";
import { resetStreaming } from "modules/streaming/redux/streamingSlice";
import { resetTimer } from "modules/timer/redux/slice";
import { selectUserId } from "modules/auth/redux/selectors";
import { subscribeToGlobalUserPreference } from "modules/globalUserPreference";
import { selectIsMobile } from "modules/deviceInfo";
import { useAppDispatch } from "store/hooks";
import useChatFirestore from "modules/chat/firestore/useChatFirestore";
import { setVisible as setAssignedTableNudgeVisible } from "modules/assignTables/redux";
import { reset as resetEnforceTableAssignment } from "modules/enforceTableAssignment/redux";
import { useDaily } from "@daily-co/daily-react";
import { reset as resetEmbedContent } from "modules/eventEmbedContent/redux";
import { closeFullScreenEventContent } from "modules/eventContent";
import broadcastActions from "modules/broadcast/redux/actions";
import { reset as resetTranscription } from "modules/transcription/redux";
import { resetUserSessionInteractions } from "modules/session/redux/slice";
import { resetUserData } from "modules/audioVideo/redux/slice";
import { resetEventMember } from "modules/eventMember/redux/slice";
import { setInitiallyLocated } from "modules/mapNavigation/redux/mapNavigationSlice";
import useRoomWhiteboardActions from "modules/roomWhiteboard/hooks/useRoomWhiteboardActions";
import { selectCurrentRoomId } from "store/rooms";
import {
  getCurrentAdmin,
  listenForEventDetails,
  stopListenEventDetails,
} from "../firebase";
import {
  listenEventUnload,
  stopListeningEventUnload,
} from "../utils/eventUtils";
import { viewEvent } from "../utils/chatEventUtils";
import { setCurrentEventId } from "../eventSlice";
import { selectCurrentEventId } from "../selectors";
import { resetUsersInEvent } from "../usersInEvent/actions";

const useEnterLeaveEvent = (eventId: string | null = null) => {
  const daily = useDaily();
  const dispatch = useAppDispatch();
  const currentUserId = useSelector(selectUserId);
  const roomId = useSelector(selectCurrentRoomId);
  const currentEventId = useSelector(selectCurrentEventId);
  const currentEventDirectories = useSelector(selectCurrentEventDirectories);
  const { unsubscribeAll } = useChatFirestore();
  const isMobileView = useSelector(selectIsMobile);
  const { revokeTranscriptionRequest } = useTranscriptionRequests();
  const { clearCurrentSession } = useUserSession();
  const { closeWhiteboard } = useRoomWhiteboardActions();

  const enterEvent = useCallback(async () => {
    if (currentUserId && eventId) {
      localStorage.setItem("eventId", eventId);

      if (!isMobileView) {
        listenEventUnload();
      }

      await getCurrentAdmin(eventId);
      subscribeToGlobalUserPreference();
      dispatch(setCurrentEventId(eventId));
      listenForEventDetails();
      viewEvent();
      daily?.startRemoteParticipantsAudioLevelObserver(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, currentUserId]);

  const leaveEvent = useCallback(
    () => {
      localStorage.removeItem("eventId");
      logger.info(`[CurrentEvent] Leave event: ${eventId}`);
      daily?.leave();

      if (currentEventId || eventId) {
        // only preserve streaming state across events if there's an active event directory
        if (!currentEventDirectories.length) {
          dispatch(resetStreaming());
        }
        revokeTranscriptionRequest();
        stopListeningEventUnload();
        dispatch(resetUsersInEvent());
        closeWhiteboard({ roomId });
        dispatch(resetChat());
        dispatch(resetUserSessionInteractions());
        dispatch(resetLobby());
        dispatch(resetUserData());
        dispatch(resetPresenceInfo());
        stopListenEventDetails();
        dispatch(updateActiveChannel(null));
        resetRooms();
        dispatch(setCurrentEventId(null));
        dispatch(resetTimer());
        dispatch(resetTranscription());
        dispatch(resetAudienceViewState());
        dispatch(resetSpace());
        dispatch(resetEventMember());
        dispatch(resetEmbedContent());
        dispatch(setAssignedTableNudgeVisible(false));
        dispatch(resetEnforceTableAssignment());
        dispatch(closeFullScreenEventContent());
        dispatch(broadcastActions.leaveBroadcast());
        dispatch(setInitiallyLocated(false));
        daily?.stopRemoteParticipantsAudioLevelObserver();

        // Unsubscribe from all channels
        unsubscribeAll();
      }
      if (currentEventId) {
        clearCurrentSession({ eventId: currentEventId });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, currentEventId, currentEventDirectories],
  );

  return { enterEvent, leaveEvent };
};

export default useEnterLeaveEvent;
