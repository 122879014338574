import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { PollIcon } from "../../assets/Poll";
import { useStyles } from "./styles";

export const NoActivePolls = ({
  onClick,
  isHost,
}: {
  onClick: () => void;
  isHost: boolean;
}) => {
  const styles = useStyles();
  const { t } = useI18n(["event"]);

  return (
    <Container className={styles.root} flex column top="sm">
      <Container flex fullWidth top="xs">
        <PollIcon className={styles.pollIcon} />
      </Container>
      <Container flex fullWidth top="xs">
        <Typography variant="h5">{t("polls.no.active.polls")}</Typography>
      </Container>
      {isHost && (
        <>
          <Container bottom="sm" flex fullWidth top="xs">
            <Typography align="center" variant="subtitle2">
              {t("polls.create.prompt")}
            </Typography>
          </Container>
          <Container flex fullWidth top="xs">
            <Button
              onClick={() => {
                onClick();
              }}
            >
              {t("polls.create.button")}
            </Button>
          </Container>
        </>
      )}
    </Container>
  );
};
