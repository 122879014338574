import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  sectionTitle: {
    margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: `${theme.palette.text.tertiary} !important`,
    opacity: 0.9,
    "&:firstChild": {
      marginTop: 0,
    },
  },
}));

export default useStyles;
