import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";

const useStyles = makeStyles<Theme>((theme) => ({
  iconButton: {
    color: theme.palette.icon.primary,
  },
}));

export default useStyles;
