import { ComponentProps } from "react";
import { Theme } from "@mui/material/styles";
import { StyleRulesCallback } from "@mui/styles/withStyles";
import { LightInput } from ".";

export const styles: StyleRulesCallback<
  Theme,
  ComponentProps<typeof LightInput>
> = (theme) => ({
  container: (props) => ({
    width: props.fullWidth ? "100%" : "auto",
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  }),
  root: (props) => ({
    fontSize: 14,
    borderRadius: 5,
    margin: "5px 0",
    overflow: "hidden",
    position: "relative",
    height: props.size === "multi" ? "unset" : "55px",
    border: `1px solid ${theme.palette.border.default}`,
    boxShadow: "4px 4px 4px 0px #0C38911A",
    transition: "border 0.3s ease",
    "&:hover": {
      border: `1px solid ${theme.palette.events.bluePrimary} !important`,
    },
    "&:focus-within": {
      border: `1px solid ${theme.palette.events.bluePrimary} !important`,
    },
    "&.Mui-disabled": {
      border: `1px solid ${theme.palette.events.grayPrimary}`,
      pointerEvents: "none",
      opacity: 0.6,
    },
  }),
  disabled: {
    background: "#fff !important",
    borderColor: theme.palette.events.grayPrimary,
    color: `${theme.palette.events.grayPrimary} !important`,
  },
  input: {
    padding: "8px 15px",
    "&::placeholder": {
      color: theme.palette.events.grayTertiary,
    },
  },
  inputDisabled: {
    "&::placeholder": {
      color: `${theme.palette.events.grayPrimary} !important`,
      opacity: "100%",
    },
  },

  error: {
    border: `1px solid ${theme.palette.events.errorDark}`,
    "&:hover": {
      border: `1px solid ${theme.palette.events.errorDark} !important`,
    },
  },
  label: {
    fontSize: 14,
    color: theme.palette.text.primary,
    padding: 0,
    margin: 0,
  },
  description: {
    fontSize: "10px !important",
    fontWeight: 500,
    color: theme.palette.events.bluePrimary,
    padding: "0 !important",
    margin: "0 !important",
  },
  errorDescription: {
    color: theme.palette.events.errorDark,
  },
});
