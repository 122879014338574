import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  addSpeakerButtonContent: {
    textTransform: "none",
  },
}));
