import { Theme } from "@remo-co/ui-core/src/types";

export const styles = {
  root: {
    "& .MuiPaper-root.MuiDialog-paper": {
      height: "auto",
      maxWidth: "250px",
      width: "180px",
      backgroundColor: (theme: Theme) => theme.palette.events.graySecondary,
      color: (theme: Theme) => theme.palette.events.graySecondary,
      padding: "0",
    },
    "&.model-window-container .MuiDialogTitle-root .close-button": {
      color: "text.primary",
    },
  },
  content: {
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "10px",
  },
  container: {
    position: "absolute",
    top: "18%",
    left: "10px",
    transform: "translateY(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: (theme: Theme) => theme.palette.events.blueAccent2,
    color: "events.whiteTeritary",
    borderRadius: "20px",
    padding: "3px 10px",
    width: "48px",
    height: "24px",
  },
  button: {
    width: "121px",
    height: "38px",
    borderRadius: "20px",
    padding: "10px 30px",
    backgroundColor: (theme: Theme) => theme.palette.events.blueSecondary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
    color: (theme: Theme) => theme.palette.text.primary,
  },
};
