import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { useSelector } from "react-redux";
import { IChat } from "modules/chat/types";
import { useI18n } from "i18n";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import classNames from "classnames";
import { useDeleteMessage } from "modules/chat/hooks/useDeleteMessages";
import { useAppDispatch } from "store/hooks";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useStyles } from "./styles";

interface Props {
  message: IChat;
  isMe: boolean;
}

const DeleteMessageButton = ({ message, isMe }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["common", "event"]);
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles();

  const { deleteMessage, isPending } = useDeleteMessage();

  const onDeleteButtonClick = () => {
    dispatch(
      addDialogNotification({
        message: t("event:yes.delete.chat"),
        title: t("event:delete.chat"),
        confirmText: t("button.confirm.delete"),
        confirmBtnId: "sc-msg-delete-confirm",
        className: isInBroadcast ? "dark" : "",
        onConfirm: async () => {
          deleteMessage({ channelId: message.roomId, messageId: message.id });
        },
      }),
    );
  };

  return (
    <Tooltip title={t("remove.this.message") ?? ""} placement="top">
      {(isPending && (
        <CircularProgress
          className={classNames(classes.deleteButton, {
            [classes.deleteSentMessage]: isMe,
          })}
          size={18}
        />
      )) || (
        <IconButton
          className={classNames(classes.deleteButton, {
            [classes.deleteSentMessage]: isMe,
          })}
          onClick={onDeleteButtonClick}
          disableRipple
          disableFocusRipple
          data-testid="delete-button"
          size="large"
        >
          <Delete className={classes.deleteIcon} />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default DeleteMessageButton;
